// breakpoints
$phone: 400px;
$phone-wide: 480px;
$phablet: 560px;
$tablet-small: 640px;
$tablet: 768px;
$tablet-wide: 1024px;
$container: 1103px;
$desktop: 1248px;
$desktop-wide: 1440px;

:root {
  --easing: cubic-bezier(0.645, 0.045, 0.355, 1);
  // --darkBackground: #205072;
  --darkBackground: #1c2225;
  --darkist: #437285;
  --darker: #669498;
  --dark: #89b6ac;
  --light: #acd8bf;
  --lighter: #cffad2;
  --fontlight: #a8b2d1;
  --fontlighter: #ccd6f6;
  --fontLightist: #e6f1ff;
  --fontHighlight: #ffb464;
  --fontFamilyHeading: "SF Pro", sans-serif;
  --fontFamilyContent: "RobotoMono", monospace;
  --fontFamilySubHeading: "SF Pro", sans-serif;
}

// application base

body {
  background-color: var(--darkBackground);
}

.App {
  font-family: var(--fontFamilyHeading);
  color: var(--fontLightist);
  position: relative;
}

// globals

.load-animation {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-container {
  position: fixed;
  z-index: -1;
  .canvas {
    background-color: var(--darkBackground);
    transition: opacity 300ms var(--easing);
  }
}
.blobs-wrapper {
  position: fixed;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  overflow: hidden;

  @media (min-width: $tablet) {
    width: 95vw;
    height: 95vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &-relative {
    position: relative;
    height: 100%;
    width: 100%;
    max-height: 100vh;
    overflow: hidden;
  }
}

.blob-wrapper {
  position: absolute;
  height: 150%;
  width: 150%;
  @media screen and (orientation: landscape) {
    height: 100%;
    width: 100%;
  }
  @media (min-width: $tablet) {
    height: 100%;
    width: 100%;
  }
  path {
    transition: opacity 300ms var(--easing);
    opacity: 0.3;
  }
  &--parent-lr {
    right: 0px;
    bottom: 0px;
  }
  &--ul {
    position: absolute;
    top: -19px;
    left: -14px;
    @media (min-width: $tablet) {
      top: 0px;
      left: 0px;
    }
  }
  &--lr {
    position: absolute;
    bottom: -15px;
    right: -22px;
    @media (min-width: $tablet) {
      bottom: 0px;
      right: 0px;
    }
  }
}

.hero-wrapper {
  position: relative;
  width: 100vw;
  min-height: max-content;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
}

.observer-blobs {
  position: absolute;
  bottom: 25%;
  height: 50%;
  width: 100%;
  &--ref {
    position: relative;
  }
}

.nav-bar {
  z-index: 100;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  font-family: var(--fontFamilyContent);
  color: var(--fontLightist);
  transition: transform var(--easing) 0.3s;
  &__container {
    margin: 26px 75px;
    display: flex;
    justify-content: space-between;
    p {
      align-self: center;
    }
    ol {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      column-gap: 1rem;
      height: 100%;
      li {
        height: 100%;
        align-self: center;
        font-size: 1rem;
      }
      span {
        color: var(--fontHighlight);
        margin-right: 5px;
      }
      li:last-child {
        padding: 0.75rem 1rem;
        border: solid 1px var(--fontHighlight);
      }
    }
  }
}

.highlight-text {
  font-family: var(--fontFamilyContent);
  color: var(--fontHighlight);
  font-size: 1rem;
  margin-bottom: 15px;
}

.heading-text {
  color: var(--fontLightist);
  font-size: 3rem;
  margin-bottom: 8px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -2px;
  @media (min-width: $tablet) {
    font-size: 70px;
  }
  @media (min-width: $tablet-wide) {
    font-size: 92px;
  }
}

.subheading-text {
  color: var(--fontlight);
  font-size: 2.2rem;
  // font-weight: 700;
  margin-bottom: 26px;
  // letter-spacing: -1px;
  line-height: 2.3rem;
  font-weight: normal;
  font-style: normal;
  letter-spacing: -2px;
  @media (min-width: $tablet) {
    font-size: 58px;
    line-height: 58px;
  }
  @media (min-width: $tablet-wide) {
    font-size: 65px;
    line-height: 60px;
  }
}

.content-text {
  color: var(--fontlight);
  font-family: var(--fontFamilyContent);
  line-height: 20px;
  letter-spacing: -0.5px;
  font-size: 1rem;
}

.disappear {
  opacity: 0 !important;
}

.fadeup-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 300ms var(--easing), transform 300ms var(--easing);
}

.fadeup-enter {
  opacity: 0.01;
  transform: translateY(20px);
}

.fadedown-leave {
  opacity: 0.01;
  transform: translateY(-20px);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 0px 0px;
  @media (min-width: $tablet) {
    padding: 0px 50px;
  }
}

.relative-wrapper {
  position: relative;
}

.underline {
  background-color: var(--fontHighlight);
  height: 1px;
  display: block;
  position: relative;
  z-index: -1;
}

.about-section {
  height: 100vh;
  padding: 10%;
  &__heading {
    width: max-content;
  }
  &__img {
    position: relative;
    height: 450px;
    width: 460px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 5px;
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: var(--dark);
      opacity: 0.4;
    }
  }
}

.experience-section {
  min-height: 100vh;
  padding: 10px;
  color: var(--fontlight);
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  &__nav {
    position: relative;
    border-bottom: 1px solid var(--fontlight);
    display: flex;
    column-gap: 5px;
    width: max-content;
    button {
      cursor: pointer;
      color: var(--fontlight);
      background-color: var(--darkBackground);
      border: none;
      padding: 5px;
      transition: background-color 0.2s ease-in, color 0.2s ease-in;
      &:hover {
        color: var(--fontHighlight);
        background-color: var(--darkist);
      }
    }
    .current-tab {
      color: var(--fontHighlight);
    }
    .slected-tab-underline {
      position: absolute;
      height: 1px;
      bottom: -1px;
      background-color: var(--fontHighlight);
      transition: width 0.2s ease-in, left 0.2s ease-in;
    }
  }
}
