* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @font-face {
  font-family: "SF Pro";
  src: local("SF Pro Display Light"), local("SF-Pro-Display-Light"),
    url("./fonts/SF-Pro/SF-Pro-Display-Light.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: "SF Pro";
  src: local("SF Pro Display Medium"), local("SF-Pro-Display-Medium"),
    url("./fonts/SF-Pro/SF-Pro-Display-Medium.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: "SF Pro";
  src: local("SF Pro Display Heavy"), local("SF-Pro-Display-Heavy"),
    url("./fonts/SF-Pro/SF-Pro-Display-Heavy.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: "SF Pro";
  src: local("SF Pro Display Regular"), local("SF-Pro-Display-Regular"),
    url("./fonts/SF-Pro/SF-Pro-Display-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "SF Pro";
  src: local("SF Pro Display Semibold"), local("SF-Pro-Display-Semibold"),
    url("./fonts/SF-Pro/SF-Pro-Display-Semibold.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "SF Pro";
  src: local("SF Pro Display Bold"), local("SF-Pro-Display-Bold"),
    url("./fonts/SF-Pro/SF-Pro-Display-Bold.otf") format("truetype");
  font-weight: 700;
  font-style: bold;
} */

/* @font-face {
  font-family: SF-Pro;
  src: local("SF-Pro"),
    url(./fonts/SF-Pro-Display-Regular.otf) format("opentype");
} */

/* @font-face {
  font-family: SF-Pro;
  src: local("SF-Pro"), url(./fonts/SF-Pro-Display-Bold.otf) format("opentype");
  font-weight: bolder;
}

@font-face {
  font-family: SF-Pro;
  src: local("SF-Pro"),
    url(./fonts/SF-Pro-Display-Semibold.otf) format("opentype");
  font-weight: bold;
} */

@font-face {
  font-family: RobotoMono;
  src: url(./fonts/RobotoMono-ExtraLight.ttf) format("opentype");
}
