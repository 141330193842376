@import "./Breakpoints.scss";

.hero-card {
  position: relative;
  justify-content: center;
  display: flex;
  overflow: hidden;
  height: 100vh;
  // border-radius: 5px;
  @media (min-width: $tablet) {
    height: 100%;
    width: 100%;
    height: 95vh;
    border-radius: 5px;
  }
  &__content {
    padding: 16px;
    color: #ffb464;
    display: flex;
    flex-direction: column;
    // row-gap: 16px;
    justify-content: center;
    text-align: left;
    @media (min-width: $tablet) {
      padding: 10%;
      // row-gap: 26px;
    }
    &__description {
      max-width: 93%;
      @media (min-width: $tablet) {
        max-width: 70%;
      }
    }
  }
  .connect-button {
    padding: 0.75rem 1rem;
    display: block;
    width: max-content;
    border: solid 1px var(--fontHighlight);
    background-color: transparent;
    // color: var(--fontlight);
    color: var(--fontHighlight);
    font-family: var(--fontFamilyContent);
    cursor: pointer;
    margin-top: 25px;
    text-decoration: none;
  }
}
.intersection-hero-top {
  position: absolute;
  top: 15%;
  height: 25%;
  width: 100vw;
  // background-color: rgba(255, 99, 71, 0.101);
  @media (min-width: $tablet) {
    top: 25%;
  }
  &--top {
    position: relative;
  }
}
.intersection-hero-bottom {
  position: absolute;
  bottom: 25%;
  height: 25%;
  width: 100vw;
  // background-color: rgba(255, 99, 71, 0.101);
  &--bottom {
    position: relative;
  }
}
